<template>
  <div>
    <table class="dataTable" :class="{ 'table-border': hasTableBorder, 'table-not-odd': notOddBackgroundColor }">
      <thead>
        <tr>
          <template v-for="(item, index) in labels">
            <th v-if="item.isCheckbox && isCheckAllShown" class="dataTable-fix" :key="index">
              <label class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  name=""
                  :disabled="(isNoData || isDisabledCheckAll) ? true : false"
                  :checked="isAllChecked"
                  @click="checkAll"
                />
                <span class="form-check-label"></span>
              </label>
            </th>
            <th v-else-if="item.sortKey && !item.notShow" class="dataTable-fix" :key="`${item.name}-${index}`">
              <div class="sort">
                <p class="sort-text" :class="{ 'is-active': sortText === item.sortKey }">{{ item.name }}</p>
                <ul class="sort-toggle">
                  <li class="sort-toggle-item">
                    <button
                      class="sort-toggle-btn"
                      type="button"
                      :class="{ 'is-active': sortText === item.sortKey && sortType === 'asc' }"
                      @click="sortBy(item.sortKey, 'asc')"
                    >
                      <i class="aikon aikon-chevron_up"></i>
                    </button>
                  </li>
                  <li class="sort-toggle-item">
                    <button
                      class="sort-toggle-btn"
                      type="button"
                      :class="{ 'is-active': sortText === item.sortKey && sortType === 'desc' }"
                      @click="sortBy(item.sortKey, 'desc')"
                    >
                      <i class="aikon aikon-chevron_down"></i>
                    </button>
                  </li>
                </ul>
              </div>
            </th>
            <th v-else-if="item.isButtonColumn || item.isInputColumn" class="dataTable-fix" :key="`${item.name}--${index}`">{{ item.name }}</th>
            <th v-else-if="!item.notShow" :key="`${item.name}---${index}`">{{ item.name }}</th>
          </template>
        </tr>
      </thead>
      <tbody v-if="dataTable">
        <tr v-for="data in dataTable" :class="{ 'disabled-row': data.isRowDisabled }" :key="data.index">
          <template v-for="(item, index) in labels">
            <td v-if="item.isCheckbox" :key="index">
              <label class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  :value="!data.isCheckboxDisabled ? data[checkedKey] : ''"
                  :disabled="data.isCheckboxDisabled"
                  v-model="checkedItems"
                  @change="check"
                />
                <span class="form-check-label"></span>
              </label>
            </td>
            <td v-if="item.isButtonColumn" :key="index">
              <ul class="listGrid flex-nowrap justify-content-end">
                <li v-for="(button, index) in (item.key ? data[item.key] : data.buttonLinks)" :key="index" class="listGrid-item">
                  <router-link
                    v-if="button.routeName"
                    :class="`btn btn-sm ${button.class}`"
                    :to="{ name: button.routeName, params: button.params }"
                    >{{ button.content }}</router-link
                  >
                  <button v-if="button.function && !button.isNotShown" :class="button.class" type="button" @click="button.function">
                    {{ button.content }}
                  </button>
                </li>
              </ul>
            </td>
            <td v-else-if="item.isStatusColumn" :key="`${item.name}-${index}`">
              <ul class="listGrid flex-wrap justify-content-start flex-column">
                <li v-for="(status, index) in data[item.key]" :key="index" class="listGrid-item">
                  <span v-if="status && status.class" class="status" :class="status.class">
                    {{ status.content }}
                  </span>
                </li>
              </ul>
            </td>
            <td v-else-if="item.key && !item.notShow" :key="`${item.name}--${index}`" :class="item.tdClass">
              <span v-if="data[item.key] && data[item.key].class" class="status" :class="data[item.key].class">{{
                data[item.key].content
              }}</span>
              <label v-else-if="data[item.key] && data[item.key].isToggleButton" class="form-toggle">
                <input
                  class="form-toggle-input"
                  type="checkbox"
                  :disabled="data[item.key].isDisabled"
                  :checked="data[item.key].value"
                  @click="handleToggle(data[item.key].id, data[item.key].value, data[item.key].shopId)"
                />
                <span class="form-toggle-set">
                  <span class="form-toggle-bg"></span>
                  <span class="form-toggle-circle"></span>
                </span>
              </label>
              <div class="form-group" v-else-if="data[item.key] && data[item.key].isInputType">
                <div class="form-group-item">
                  <input
                    v-if="data[item.key].directive === 'decimal'"
                    :class="{ 'form-control w-60': true, 'is-error': errors[data[item.key].name] }"
                    :type="data[item.key].type"
                    :name="data[item.key].label"
                    :value="data[item.key].value"
                    :disabled="data[item.key].disabled"
                    v-decimal
                    @input="handleChange($event, data[item.key].id)"
                  />
                  <input
                    v-else
                    :class="{ 'form-control w-60': true, 'is-error': errors[data[item.key].name] }"
                    :type="data[item.key].type"
                    :name="data[item.key].label"
                    :value="data[item.key].value"
                    :disabled="data[item.key].disabled"
                    v-number="data[item.key].directive"
                    @input="handleChange($event, data[item.key].id)"
                  />
                </div>
                <div class="form-group-item"><label>{{ data[item.key].label }}</label></div>
              </div>
              <div v-else-if="data[item.key] && data[item.key].type === 'image'" class="thumbnail">
                <img v-if="data[item.key].value" class="thumbnail-img" :src="data[item.key].value" />
              </div>
              <div v-else-if="data[item.key] && data[item.key].color" :class="data[item.key].color">
                {{ data[item.key].content }}
              </div>
              <template v-else-if="data[item.key] && data[item.key].routeName">
                <router-link
                  v-if="data[item.key].isLink"
                  class="link"
                  :to="{ name: data[item.key].routeName, params: data[item.key].params }"
                >
                  {{ data[item.key].content }}
                </router-link>
                <template v-else>{{ data[item.key].content }}</template>
              </template>
              <template v-else-if="data[item.key] && Array.isArray(data[item.key]) && item.keyMultiple">
                <p v-for="(child, index) in data[item.key]" :class="child.class" :key="index">{{ child.value }}</p>
              </template>
              <template v-else><p>{{ data[item.key] }}</p></template>
            </td>
          </template>
        </tr>
        <tr v-if="isNoData" class="noData">
          <td colspan="999">{{ noDataMessage }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import checkall from '@/mixins/plugin/checkall';
import sort from '@/mixins/plugin/sort';
import { COMMON_MESSAGE } from '@/config/message';
import error from '@/mixins/plugin/error';

export default {
  props: {
    dataTable: {
      type: Array,
      required: true,
    },
    currentCheckedItems: {
      type: Array,
    },
    labels: {
      type: Array,
      required: true,
    },
    storeModule: {
      type: String,
    },
    checkedKey: {
      type: String,
      default: 'id',
    },
    count: {
      type: Number,
    },
    unAuthorized: {
      type: Boolean,
      default: false,
    },
    isDisabledCheckAll: {
      type: Boolean,
      default: false
    },
    hasTableBorder: {
      type: Boolean,
      default: false,
    },
    isCheckAllShown: {
      type: Boolean,
      default: true,
    },
    notOddBackgroundColor: {
      type: Boolean,
      default: false,
    }
  },
  mixins: [checkall, sort, error],
  computed: {
    lists() {
      return this.dataTable;
    },
    isNoData() {
      return this.dataTable.length === 0;
    },
    noDataMessage() {
      return this.unAuthorized ? COMMON_MESSAGE.unAuthorizedTable : COMMON_MESSAGE.noData;
    },
    checkedData() {
      return {
        allFlag: this.isAllChecked && this.isCheckAllShown,
        ids: this.isAllChecked && this.isCheckAllShown ? this.removedItems : this.checkedItems,
        count: this.isAllChecked && this.isCheckAllShown ? this.count - this.removedItems?.length : this.checkedItems?.length,
      };
    },
  },
  watch: {
    checkedData(val) {
      this.$emit('select-items', val);
    },
    dataTable() {
      if (this.isAllChecked) {
        if (!this.lists.length) {
          this.isAllChecked = false
        }
        for (const item of this.lists) {
          if (!this.checkedItems.includes(item[this.checkedKey])) {
            this.checkedItems.push(item[this.checkedKey]);
          }
          if (this.removedItems.includes(item[this.checkedKey])) {
            this.checkedItems = this.checkedItems.filter((value) => value !== item[this.checkedKey]);
          }
        }
      }
    },
    currentCheckedItems(val) {
      this.resetCheck();
      this.checkedItems = [...val];
    },
  },
  methods: {
    handleToggle(id, status, shopId) {
      let newStatus = !status;
      this.$emit('handle-toggle', id, newStatus, shopId);
    },
    handleChange(e, id) {
      this.$emit('handle-change', id, e.target.value);
    },
    resetCheck() {
      this.isAllChecked = false;
      this.checkedItems = [];
      this.removedItems = [];
      this.uncheckedItems = [];
      this.isAllUnchecked = false;
    },
  },
};
</script>

<style scoped>
.disabled-row {
  background: #b2b7d2 !important;
}

.w-60 {
  width: 60px !important;
}

.lh-40 {
  line-height: 40px;
}

.lh-60 {
  line-height: 60px;
}

.mw-190 {
  min-width: 190px;
}

.mw-130 {
  min-width: 130px;
}

.mw-120 {
  min-width: 120px;
}

.mw-110 {
  min-width: 110px;
}

.table-not-odd tbody tr:nth-child(odd) {
  background: transparent !important;
}
</style>
