<template>
  <div class="form">
    <section class="form-section">
      <p class="form-headline">基本情報</p>
      <FormRow :required="true">
        <template v-slot:label>イベント名</template>
        <template v-slot:labelNote>（20文字以内）</template>
        <template v-slot:content>
          <div class="form-content-row">
            <p v-if="isEdit">{{ form.name }}</p>
            <input
              v-else
              :class="{ 'form-control': true, 'is-error': errors.name }"
              type="text"
              name="name"
              maxlength="20"
              v-trim
              v-maxlength
              v-model="form.name"
            />
          </div>
        </template>
      </FormRow>
      <FormRow :need="true">
        <template v-slot:label>イベント概要</template>
        <template v-slot:labelNote>（2,000文字以内）</template>
        <template v-slot:content>
          <div class="form-content-row">
            <textarea
              :class="{ 'form-control form-textarea': true, 'is-error': errors.description }"
              name="description"
              maxlength="2000"
              v-trim
              v-maxlength
              v-model="form.description"
            ></textarea>
          </div>
        </template>
      </FormRow>
      <FormRow :required="true">
        <template v-slot:label>イベントタイプ</template>
        <template v-slot:content>
          <div class="form-content-row">
            <ul v-if="isEditChargeGiftCard || ($permission.isOffice() && isChargeOrPaymentGiftCardType)" class="listGroup">
              <li>
                <label class="form-radio">
                  <span v-if="form.type === chargeGiftCardType">TICKETチャージタイプ</span>
                  <span v-if="form.type === paymentGiftCardType">TICKET支払いのみタイプ</span>
                </label>
              </li>
            </ul>
            <ul v-else-if="isEditWithoutPaymentType" class="listGroup">
              <li>
                <label class="form-radio">
                  <span v-if="form.type === giftType">商品券タイプ</span>
                  <span v-if="form.type === chargeType">チャージタイプ</span>
                  <span v-if="form.type === paymentType">支払いのみタイプ</span>
                </label>
              </li>
              <li v-if="form.type === chargeType" class="listGroup-item">
                <ul class="listGrid">
                  <label class="form-check form-check-btn">{{ selectedCharges }}</label>
                </ul>
              </li>
            </ul>
            <ul v-else-if="isEditWithPaymentType" class="listGroup">
              <li class="listGroup-item">
                <label class="form-radio">
                  <input :class="{ 'form-radio-input': true, 'is-error': errors.type }" type="radio" :value="chargeType" name="type" v-model="form.type" />
                  <span class="form-radio-label">チャージタイプ</span>
                </label>
                <transition name="fade">
                  <ul v-if="form.type === chargeType" class="listGrid">
                    <li v-for="item in chargeChoices" :key="item.index" class="listGrid-item">
                      <label class="form-check form-check-btn">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          :value="item.id"
                          :checked="checkChargeChoices(String(item.id))"
                          @input="handleChargeChoice"
                        />
                        <span :class="{ 'form-check-label': true, 'is-error': errors.chargeChoices }">{{
                          item.name
                        }}</span>
                      </label>
                    </li>
                  </ul>
                </transition>
              </li>
              <li class="listGroup-item">
                <label class="form-radio">
                  <input :class="{ 'form-radio-input': true, 'is-error': errors.type }" type="radio" :value="paymentType" name="type" v-model="form.type" />
                  <span class="form-radio-label">支払いのみタイプ</span>
                </label>
              </li>
            </ul>
            <ul v-else-if="useTicketFlag" class="listGroup">
              <li class="listGroup-item">
                <label class="form-radio">
                  <input :class="{ 'form-radio-input': true, 'is-error': errors.type }" type="radio" :value="chargeGiftCardType" name="type" v-model="form.type" />
                  <span class="form-radio-label">TICKETチャージタイプ</span>
                </label>
              </li>
              <li class="listGroup-item">
                <label class="form-radio">
                  <input :class="{ 'form-radio-input': true, 'is-error': errors.type }" type="radio" :value="paymentGiftCardType" name="type" v-model="form.type" />
                  <span class="form-radio-label">TICKET支払いのみタイプ</span>
                </label>
              </li>
            </ul>
            <ul v-else class="listGroup">
              <li class="listGroup-item">
                <label class="form-radio">
                  <input :class="{ 'form-radio-input': true, 'is-error': errors.type }" type="radio" :value="chargeType" name="type" v-model="form.type" />
                  <span class="form-radio-label">チャージタイプ</span>
                </label>
                <transition name="fade">
                  <ul v-if="form.type === chargeType" class="listGrid">
                    <li v-for="item in chargeChoices" :key="item.index" class="listGrid-item">
                      <label class="form-check form-check-btn">
                        <input class="form-check-input" type="checkbox" :value="item.id" :checked="checkChargeChoices(String(item.id))" @input="handleChargeChoice"/>
                        <span :class="{ 'form-check-label': true, 'is-error': errors.chargeChoices }">{{ item.name }}</span>
                      </label>
                    </li>
                  </ul>
                </transition>
              </li>
              <li class="listGroup-item">
                <label class="form-radio">
                  <input :class="{ 'form-radio-input': true, 'is-error': errors.type }" type="radio" :value="paymentType" name="type" v-model="form.type" />
                  <span class="form-radio-label">支払いのみタイプ</span>
                </label>
              </li>
            </ul>
          </div>
        </template>
      </FormRow>
      <FormRow :required="true">
        <template v-slot:label>事務局名/親企業名</template>
        <template v-slot:content>
          <div class="form-content-row">
            <label v-if="isEdit && !$permission.isGmo()" class="form-check form-check-btn">{{ selectedClients }}</label>
            <SearchSelectDisabled
              v-else
              :isError="errors.clients"
              :options="newParentClients"
              :canEdit="isEdit"
              :blockKeys="['Delete']"
              :initialValue="form.clients"
              @change-selection="handleClients"
            />
          </div>
        </template>
      </FormRow>
      <FormRow>
        <template v-slot:label>準事務局名/子企業名</template>
        <template v-slot:content>
          <div class="form-content-row">
            <SearchSelect
              :isError="errors.otherClients"
              :options="childClients"
              :initialValue="form.otherClients"
              @change-selection="handleOtherClients"
              :disabled="isClientChild"
            />
          </div>
        </template>
      </FormRow>
      <FormRow v-if="isViewAllHistory">
        <template v-slot:label>全購入履歴表示設定</template>
        <template v-slot:toggle>
          <label class="form-toggle">
            <input
              class="form-toggle-input"
              type="checkbox"
              name="viewAllhistory"
              v-model="form.viewAllhistory"
            />
            <span class="form-toggle-set">
              <span class="form-toggle-bg"></span>
              <span class="form-toggle-circle"></span>
            </span>
          </label>
        </template>
        <template v-slot:content>
          <div class="form-content-row">
            <p>
              ON：準事務局/子企業でもすべての購入履歴を表示・確認・キャンセルすることが可能となります。<br />
              OFF：準事務局/子企業が管理画面から発行した分のみが表示・確認・キャンセルすることが可能となります。
            </p>
          </div>
        </template>
      </FormRow>
      <FormRow :required="true">
        <template v-slot:label>ディレクトリ名</template>
        <template v-slot:content>
          <div class="form-content-row">
            <p v-if="isEdit">{{ form.directory }}</p>
            <input
              v-else
              :class="{ 'form-control': true, 'is-error': errors.directory }"
              type="text"
              name="directory"
              v-trim
              v-maxlength
              maxlength="32"
              v-model="form.directory"
            />
          </div>
        </template>
      </FormRow>
      <FormRow>
        <template v-slot:label>案内ページURL</template>
        <template v-slot:content>
          <div class="form-content-row">
            <input
              :class="{ 'form-control': true, 'is-error': errors.informationUrl }"
              type="text"
              name="informationUrl"
              v-trim
              v-model="form.informationUrl"
            />
          </div>
        </template>
      </FormRow>
      <FormRow :required="true" v-if="!isClientChild">
        <template v-slot:label>お問い合わせページ利用</template>
        <template v-slot:toggle>
          <label class="form-toggle">
            <input
              class="form-toggle-input"
              type="checkbox"
              name="useContactFrontFlag"
              v-model="form.useContactFrontFlag"
            />
            <span class="form-toggle-set">
              <span class="form-toggle-bg"></span>
              <span class="form-toggle-circle"></span>
            </span>
          </label>
        </template>
      </FormRow>
      <FormRow :required="true">
        <template v-slot:label>加盟店審査フォーム</template>
        <template v-slot:content>
          <div class="form-content-row">
            <ul class="listGrid">
              <li v-for="item in shopFormTypeList" :key="item.index" class="listGrid-item">
                <label class="form-radio form-radio-btn">
                  <input
                    class="form-radio-input"
                    type="radio"
                    :value="item.value"
                    v-model="form.shopForm.type"
                    name="shopFormType"
                  />
                  <span :class="{'form-radio-label': true, 'is-error': errors.shopForm.type }" >{{ item.label }}</span>
                </label>
              </li>
            </ul>
          </div>
        </template>
      </FormRow>
      <FormRow :required="true">
        <template v-slot:label>加盟店審査フォーム用の自動メール送信先メールアドレス</template>
        <template v-slot:content>
          <div class="form-content-row">
            <input
              :class="{ 'form-control': true, 'is-error': errors.shopForm.email }"
              type="text"
              name="shopFormEmail"
              v-model="form.shopForm.email"
              v-trim
              v-maxlength
              v-email
              maxlength="50"
            />
          </div>
        </template>
      </FormRow>
      <FormRow>
        <template v-slot:label>使用可能期間</template>
        <template v-slot:content>
          <div class="form-content-row">
            イベントを公開する期間を制限する場合に入力してください。
          </div>
          <div class="form-content-row">
            <DateRange
              field="usablePeriod"
              :value="form.usablePeriod"
              :errorField="errors.usablePeriod"
              @on-change="onChangeDateRange"
            />
          </div>
        </template>
      </FormRow>
      <FormRow>
        <template v-slot:label>チャージ可能期間</template>
        <template v-slot:content>
          <div class="form-content-row">
            チャージ（購入）できる期間を制限する場合に入力してください。
          </div>
          <div class="form-content-row">
            <DateRange
              type="dateTime"
              field="chargeablePeriod"
              :isEnableEndTime="false"
              :isConfigNoEndTime="true"
              :isConfigNoMinutes="true"
              :defaultHour="'12'"
              :value="form.chargeablePeriod"
              :errorField="errors.chargeablePeriod"
              @on-change="onChangeDateRange"
            />
          </div>
        </template>
      </FormRow>
      <FormRow>
        <template v-slot:label>支払可能期間</template>
        <template v-slot:content>
          <div class="form-content-row">
            残高を支払いに使用できる期間を制限する場合に入力してください。
          </div>
          <div class="form-content-row">
            <DateRange
              type="dateTime"
              field="paymentablePeriod"
              :isEnableEndTime="false"
              :isConfigNoEndTime="true"
              :isConfigNoMinutes="true"
              :defaultHour="'12'"
              :value="form.paymentablePeriod"
              :errorField="errors.paymentablePeriod"
              @on-change="onChangeDateRange"
            />
          </div>
        </template>
      </FormRow>
      <transition name="fade">
        <FormRow v-if="notPaymentType" :need="true">
          <template v-slot:label>チャージ方法</template>
          <template v-slot:content>
            <div class="form-content-row">
              <p>必ず1つ以上選択してください。</p>
            </div>
            <div class="form-content-row">
              <ul class="listGrid content">
                <li class="listGrid-item">
                  <label class="form-check form-check-btn d-inline-block">
                    <input class="form-check-input" type="checkbox" :disabled="isClientChild" v-model="form.chargeType.creditcard.use" />
                    <p :class="{ 'form-check-label': true, 'is-error': errors.choiceChargeType }">クレジットカード</p>
                  </label>
                  <transition name="fade">
                    <div v-if="form.chargeType.creditcard.use">
                      <FormRow style="margin: 30px 0px 0px 45px;">
                        <template v-slot:label>使用できるカードブランド</template>
                        <template v-slot:content>
                          <div class="form-content-row">
                            <ul class="listGrid">
                              <li v-for="card in creditCards" :key="card.value" class="listGrid-item">
                                <label class="form-check form-check-btn d-inline-block">
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    name="cards"
                                    :disabled="isClientChild"
                                    v-model="form.chargeType.creditcard.cards"
                                    :value="card.value"
                                  />
                                  <span :class="{ 'form-check-label': true, 'is-error': errors.creditcard.cards }">{{ card.label }}</span>
                                </label>
                              </li>
                            </ul>
                          </div>
                        </template>
                      </FormRow>
                      <FormRow style="margin: 30px 0px 0px 45px;">
                        <template v-slot:label>3DS</template>
                        <template v-slot:content>
                          <div class="form-content-row">
                            <ul class="listGroup">
                              <li v-for="(item, index) in value3ds" :key="index" class="listGroup-item value3ds-item">
                                <label class="form-radio">
                                  <input
                                    class="form-radio-input"
                                    type="radio"
                                    name="value3ds"
                                    :disabled="isClientChild"
                                    v-model="form.chargeType.creditcard['3ds']"
                                    :value="item.value"
                                  />
                                  <span :class="{ 'form-radio-label': true, 'is-error': errors.creditcard['3ds'] }">{{ item.label }}</span>
                                </label>
                              </li>
                            </ul>
                          </div>
                        </template>
                      </FormRow>
                      <FormRow style="margin: 30px 0px 0px 45px;">
                        <template v-slot:label>キャンセル可能期間</template>
                        <template v-slot:content>
                          <div class="form-content-row">
                            クレジットカード購入後から下記の期間が経過するとキャンセルすることができなくなります。<br />
                            ※通常はマルチペイメントサービスと同様、購入日を0日として180日間になります。空欄時は180日間です。<br />
                            ※ポイント購入時付与を利用の際は、このキャンセル可能期間経過後にポイント付与されることになります。<br />
                          </div>
                          <div class="form-content-row">
                            <div class="form-group">
                              <div class="form-group-item">
                                <label v-if="isClientChild">{{ form.daysAllowCancelCharge }}</label>
                                <input
                                  v-else
                                  :class="{ 'form-control': true, 'is-error': errors.daysAllowCancelCharge }"
                                  type="tel"
                                  name="daysAllowCancelCharge"
                                  v-number="'integer'"
                                  v-model="form.daysAllowCancelCharge"
                                />
                              </div>
                              <div class="form-group-item">日間</div>
                            </div>
                          </div>
                        </template>
                      </FormRow>
                      <FormRow v-if="identityVerification || useSms" style="margin: 30px 0px 0px 45px;">
                        <template v-slot:label>認証</template>
                        <template v-slot:content>
                          <div class="form-content-row">
                            <ul class="listGroup">
                              <li v-for="(item, index) in verificationTypes" :key="index" class="listGroup-item value3ds-item">
                                <label class="form-radio">
                                  <input
                                    :class="{ 'form-radio-input': true, 'is-error': errors.settingVerify }"
                                    type="radio"
                                    name="settingVerify"
                                    :disabled="isClientChild"
                                    v-model="form.settingVerify"
                                    :value="item.value"
                                  />
                                  <span class="form-radio-label">{{ item.label }}</span>
                                </label>
                              </li>
                            </ul>
                          </div>
                        </template>
                      </FormRow>
                      <FormRow style="margin: 30px 0px 0px 45px;" v-if="useSms">
                        <template v-slot:label>支払用パスコードSMS変更</template>
                        <template v-slot:content>
                          <div class="form-content-row">
                            クレジットカードでの購入時、支払用パスコードではなく、SMS認証を行うようになります。<br />
                            ※こちらをONにする場合は、認証のSMS認証必須、本人確認＆SMS認証必須はONにしない運用がお勧めです。<br />
                            ※こちらをONにした場合は、生体認証での支払用パスコード認証もなくなります。<br />
                          </div>
                          <div class="form-content-row">
                            <ul class="listGroup">
                              <li v-for="(item, index) in useChargeSmsTypes" :key="index" class="listGroup-item value3ds-item">
                                <label class="form-radio">
                                  <input
                                    :class="{ 'form-radio-input': true, 'is-error': errors.useChargeSms }"
                                    type="radio"
                                    name="useChargeSms"
                                    :disabled="isClientChild"
                                    v-model="form.useChargeSms"
                                    :value="item.value"
                                  />
                                  <span class="form-radio-label">{{ item.label }}</span>
                                </label>
                              </li>
                            </ul>
                          </div>
                        </template>
                      </FormRow>
                    </div>
                  </transition>
                </li>
                <li class="listGrid-item">
                  <label class="form-check form-check-btn d-inline-block">
                    <input class="form-check-input" type="checkbox" :disabled="isClientChild" v-model="form.chargeType.convenienceStore.use" />
                    <p :class="{ 'form-check-label': true, 'is-error': errors.choiceChargeType }">コンビニ払い</p>
                  </label>
                  <transition name="fade">
                    <div v-if="form.chargeType.convenienceStore.use" style="margin: 30px 0px 0px 45px;">
                      <FormRow>
                        <template v-slot:label>使用できるコンビニ</template>
                        <template v-slot:content>
                          <div class="form-content-row">
                            <ul class="listGrid">
                              <li v-for="store in convenienceStores" :key="store.index" class="listGrid-item">
                                <label class="form-check form-check-btn">
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    name="stores"
                                    :disabled="isClientChild"
                                    v-model="form.chargeType.convenienceStore.stores"
                                    :value="store.code"
                                  />
                                  <span :class="{ 'form-check-label': true, 'is-error': errors.convenienceStore.stores }">{{ store.name }}</span>
                                </label>
                              </li>
                            </ul>
                          </div>
                        </template>
                      </FormRow>
                      <FormRow>
                        <template v-slot:label>事務局/企業/サービス名称</template>
                        <template v-slot:subLabel>
                          コンビニのお支払い内容確認画面、払込受領書に表示される名称となります。<br />
                          全角で16文字までとなります。
                        </template>
                        <template v-slot:content>
                          <div class="form-content-row">
                            <input
                              :class="{ 'form-control': true, 'is-error': errors.registerDisp1 }"
                              v-maxlength
                              maxlength="16"
                              type="text"
                              name="registerDisp1"
                              :disabled="isClientChild"
                              v-alphaNumSpecialFullsize
                              v-trim
                              v-model="form.registerDisp1"
                            />
                          </div>
                        </template>
                      </FormRow>
                      <FormRow>
                        <template v-slot:label>お問い合わせ先</template>
                        <template v-slot:subLabel>
                          コンビニのお支払い内容確認画面、払込受領書に表示されるお問い合わせ先名称となります。<br />
                          21文字までとなります。
                        </template>
                        <template v-slot:content>
                          <div class="form-content-row">
                            <input
                              :class="{ 'form-control': true, 'is-error': errors.registerDisp11 }"
                              v-maxlength
                              maxlength="21"
                              type="text"
                              :disabled="isClientChild"
                              name="registerDisp11"
                              v-trim
                              v-model="form.registerDisp11"
                            />
                          </div>
                        </template>
                      </FormRow>
                      <FormRow>
                        <template v-slot:label>お問い合わせ先電話番号</template>
                        <template v-slot:subLabel>
                          コンビニのお支払い内容確認画面、払込受領書に表示されるお問い合わせ先電話番号となります。<br />
                          半角数字記号にて12文字までとなります。
                        </template>
                        <template v-slot:content>
                          <div class="form-content-row">
                            <input
                              :class="{ 'form-control': true, 'is-error': errors.registerDisp12 }"
                              v-maxlength
                              maxlength="12"
                              :disabled="isClientChild"
                              type="text"
                              name="registerDisp12"
                              v-numberAndHypen
                              v-model="form.registerDisp12"
                            />
                          </div>
                        </template>
                      </FormRow>
                      <FormRow>
                        <template v-slot:label>お問い合わせ先受付時間</template>
                        <template v-slot:subLabel>コンビニのお支払い内容確認画面、払込受領書に表示されるお問い合わせ先の受付時間となります。</template>
                        <template v-slot:content>
                          <div class="form-content-row">
                            <DateRange
                              type="hourMinute"
                              field="registerDisp13"
                              :isDateTimeDisabled="isClientChild"
                              :value="form.registerDisp13"
                              :errorField="errors.registerDisp13"
                              @on-change="onChangeDateRange"
                            />
                          </div>
                        </template>
                      </FormRow>
                    </div>
                  </transition>
                </li>
                <li class="listGrid-item">
                  <label class="form-check form-check-btn d-inline-block">
                    <input class="form-check-input" type="checkbox" :disabled="isClientChild" v-model="form.chargeType.payeasy.use" />
                    <p :class="{ 'form-check-label': true, 'is-error': errors.choiceChargeType }">銀行振込（Pay-easy）</p>
                  </label>
                </li>
                <li class="listGrid-item" v-if="identityVerification && useSms">
                  <label class="form-check form-check-btn d-inline-block">
                    <input class="form-check-input" type="checkbox" :disabled="isClientChild" v-model="form.chargeType.maruPay.use" />
                    <p :class="{ 'form-check-label': true, 'is-error': errors.choiceChargeType || bankError }">銀行口座（即時チャージ）</p>
                  </label>
                  <transition name="fade">
                    <FormRow v-if="form.chargeType.maruPay.use" style="margin: 10px 0px 0px 45px;">
                      <template v-slot:description>※本人確認連携、SMS認証連携が必須となります。</template>
                      <template v-slot:label>金融機関一覧表示優先設定</template>
                      <template v-slot:subLabel>※金融機関コード(半角),金融機関コード(半角)のように指定し、先に記載しているものが上位に表示されます。</template>
                      <template v-slot:content>
                        <div class="form-content-row">
                          <input
                            :class="{ 'form-control': true, 'is-error': errors.maruPay.bankCodes || (errors.chargeType && errors.chargeType.maruPay && errors.chargeType.maruPay.bankCodes) }"
                            type="text"
                            v-trim
                            v-maxlength
                            v-numberAndComma
                            :disabled="isClientChild"
                            name="bankCodes"
                            v-model="form.chargeType.maruPay.bankCodes"
                            maxlength="49"
                          />
                        </div>
                        <div class="form-content-row">
                          <button class="d-block link" @click="handleShowBankModal">利用可能な金融機関</button>
                        </div>
                      </template>
                    </FormRow>
                  </transition>
                </li>
                <li class="listGrid-item" v-if="isUseCpmParentEvent">
                  <label class="form-check form-check-btn d-inline-block">
                    <input class="form-check-input" type="checkbox" :disabled="isClientChild" v-model="form.chargeType.store.use" />
                    <p :class="{ 'form-check-label': true, 'is-error': errors.choiceChargeType || storeError }">店舗チャージ</p>
                  </label>
                </li>
              </ul>

            </div>
          </template>
        </FormRow>
      </transition>
      <FormRow v-show="isChargeTypeOrUseTicketFlag">
        <template v-slot:label>チャージ（購入）金額の有効期限</template>
        <template v-slot:content>
          <div class="form-content-row">
            <p>顧客のチャージ（購入）金額ごとに利用期間の期限を設ける。</p>
          </div>
          <div class="form-content-row">
            <button class="link" @click="showModal">有効期限の定義</button>
          </div>
          <div class="form-content-row">
            <div class="row">
              <div class="col-4">
                <div class="form-group">
                  <div class="form-group-item">
                    <div class="form-select">
                      <select class="form-control form-select-input" v-model="form.chargeExpirationMonths">
                        <option value="">選択してください</option>
                        <option v-for="month in expirationMonthCount" :key="month" :value="month">{{ month }}</option>
                      </select>
                    </div>
                  </div>
                  <div class="form-group-item">ヵ月</div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </FormRow>
      <FormRow v-show="!!form.chargeExpirationMonths && isChargeTypeOrUseTicketFlag">
        <template v-slot:label>有効期限案内（任意）</template>
        <template v-slot:content>
          <ul class="listGroup">
            <li class="listGroup-item listGroup-point">
              <div class="form-group">
                <div class="form-group-item">
                  <p>有効期限日より</p>
                </div>
                <div class="form-group-item">
                  <input
                    :class="{ 'form-control': true, 'is-error': errors.sendMailAfterDaysCharge }"
                    type="tel"
                    v-number
                    name="sendMailAfterDaysCharge"
                    v-model="form.sendMailAfterDaysCharge"
                  />
                </div>
                <div class="form-group-item">日前</div>
              </div>
            </li>
          </ul>
          <div class="form-content-row text-red">
            <p>
              ※設定有無関わらず、チャージ金額失効日1日前には顧客へメールにて通知案内が届きます。
            </p>
          </div>
        </template>
      </FormRow>
    </section>
    <transition name="fade">
    <section v-if="isPaymentTypeAndNotUseTicketFlag" class="form-section">
      <p class="form-headline">割増率とイベントグループ分け</p>
      <transition name="fade">
        <FormRow :required="true">
          <template v-slot:label>チャージ時の割増率</template>
          <template v-slot:content>
            <div class="form-content-row">
              購入金額に割増率を乗じた金額をチャージします。<br />
              <span class="text-red">※100%で等価値、端数は切り捨てになります。</span><br />
              <span class="text-red">※例）1,000円チャージ時、120%の割増率で1,200円</span>
            </div>
            <div class="form-content-row">
              <div class="form-group">
                <div class="form-group-item">
                  <p v-if="isEditWithoutPaymentType">{{ form.rate }}</p>
                  <input
                    v-else
                    :class="{ 'form-control': true, 'is-error': errors.rate }"
                    name="rate"
                    type="text"
                    v-decimal="3"
                    v-model="form.rate"
                  />
                </div>
                <div class="form-group-item">%</div>
              </div>
            </div>
          </template>
        </FormRow>
      </transition>
      <FormRow :required="true">
        <template v-slot:label>イベントグループ分け利用</template>
        <template v-slot:toggle>
          <label class="form-toggle">
            <input
              class="form-toggle-input"
              type="checkbox"
              name="groups"
              :checked="checkedGroups"
              :disabled="isEditWithoutPaymentType || isEditTransferPaymentToCharge"
              @click="handleUseGroupFlag"
            />
            <span class="form-toggle-set">
              <span class="form-toggle-bg"></span>
              <span class="form-toggle-circle"></span>
            </span>
          </label>
        </template>
      </FormRow>
      <transition name="fade">
        <section v-if="form.groups" class="form-section">
          <div class="form-groups" v-for="(item, index) of form.groups" :key="index">
            <p class="form-headline">{{ index + 1 }}つ目のイベントグループ</p>
            <FormRow :required="true">
              <template v-slot:label>イベントグループ名</template>
              <template v-slot:labelNote>（20文字以内）</template>
              <template v-slot:content>
                <div class="form-content-row">
                  <p v-if="isEditWithoutPaymentType">{{ form.groups[index].name }}</p>
                  <input
                    v-else
                    :class="{ 'form-control': true, 'is-error': errors[`name${index}`] }"
                    type="text"
                    name="groupName"
                    v-trim
                    v-maxlength
                    maxlength="20"
                    v-model="form.groups[index].name"/>
                </div>
              </template>
            </FormRow>
            <FormRow :required="true">
              <template v-slot:label>使用できる割合</template>
              <template v-slot:labelNote>（100％以内）</template>
              <template v-slot:content>
                <div class="form-content-row">
                  <div class="form-group">
                    <div class="form-group-item">
                      <p v-if="isEditWithoutPaymentType">{{ form.groups[index].rate }}</p>
                      <input
                        v-else
                        :class="{ 'form-control': true, 'is-error': errors[`rate${index}`] }"
                        type="text"
                        name="groupRate"
                        v-decimal="3"
                        v-model="form.groups[index].rate"
                      />
                    </div>
                    <div class="form-group-item">
                      %
                    </div>
                  </div>
                </div>
              </template>
            </FormRow>
          </div>
        </section>
      </transition>
    </section>
    </transition>
    <transition name="fade">
    <section v-if="isPaymentTypeAndNotUseTicketFlag" class="form-section">
      <p class="form-headline">予算・購入に関する設定</p>
      <FormRow>
        <template v-slot:label>予算</template>
        <template v-slot:content>
          <div class="form-content-row">
            予算に到達したら購入（チャージ）を停止します。<br />
            <span class="text-red">※割増率まで<span class="text-underline text-bold">含めた</span>予算総額を入力してください。</span>
          </div>
          <div class="form-content-row">
            <div class="form-group">
              <div class="form-group-item">
                <input
                  :class="{ 'form-control': true, 'is-error': errors.budget }"
                  type="tel"
                  name="budget"
                  v-number="'integer'"
                  v-model="form.budget"
                />
              </div>
              <div class="form-group-item">円</div>
            </div>
          </div>
        </template>
      </FormRow>
      <FormRow :need="true">
        <template v-slot:label>1回あたりの購入可能金額の下限</template>
        <template v-slot:content>
          <div class="form-content-row">
            購入できる金額に下限を設ける場合に入力してください。<br />
            <span class="text-red">※割増率を<span class="text-underline text-bold">含まない</span>金額を入力してください。</span>
          </div>
          <div class="form-content-row">
            <div class="form-group">
              <div class="form-group-item">
                <input
                  :class="{ 'form-control': true, 'is-error': errors.minimumAmount }"
                  type="tel"
                  name="minimumAmount"
                  v-number="'integer'"
                  v-model="form.minimumAmount"
                />
              </div>
              <div class="form-group-item">円</div>
            </div>
          </div>
        </template>
      </FormRow>
      <FormRow>
        <template v-slot:label>購入可能単位</template>
        <template v-slot:content>
          <div class="form-content-row">
            1,000円単位など購入可能単位を指定すると、この金額の倍数でしか購入できません。<br />
            <span class="text-red">※割増率を<span class="text-underline text-bold">含まない</span>金額を入力してください。</span>
          </div>
          <div class="form-content-row">
            <div class="form-group">
              <div class="form-group-item">
                <input
                  :class="{ 'form-control': true, 'is-error': errors.minimumUnit }"
                  type="tel"
                  v-number="'integer'"
                  name="minimumUnit"
                  v-model="form.minimumUnit"
                />
              </div>
              <div class="form-group-item">円</div>
            </div>
          </div>
        </template>
      </FormRow>
      <FormRow>
        <template v-slot:label>残高に保持できる金額の上限</template>
        <template v-slot:content>
          <div class="form-content-row">
            購入して残高として持つことができる金額に上限を設ける場合に入力してください。<br />
            <span class="text-red">※割増率を<span class="text-underline text-bold">含んだ</span>金額を入力してください。</span>
          </div>
          <div class="form-content-row">
            <div class="form-group">
              <div class="form-group-item">
                <input
                  :class="{ 'form-control': true, 'is-error': errors.chargeLimit }"
                  type="tel"
                  name="chargeLimit"
                  v-number="'integer'"
                  v-model="form.chargeLimit"
                />
              </div>
              <div class="form-group-item">円</div>
            </div>
          </div>
        </template>
      </FormRow>
      <FormRow>
        <template v-slot:label>1人あたりの累計購入金額上限</template>
        <template v-slot:content>
          <div class="form-content-row">
            1人が購入・消費を繰り返し、累計で購入できる金額に上限を設ける場合に入力してください。<br />
            <span class="text-red">※割増率を<span class="text-underline text-bold">含まない</span>金額を入力して下さい。</span>
          </div>
          <div class="form-content-row">
            <div class="form-group">
              <div class="form-group-item">
                <input
                  :class="{ 'form-control': true, 'is-error': errors.maximumAmount }"
                  type="tel"
                  name="maximumAmount"
                  v-number="'integer'"
                  v-model="form.maximumAmount"
                />
              </div>
              <div class="form-group-item">円</div>
            </div>
          </div>
        </template>
      </FormRow>
    </section>
    </transition>
    <transition name="fade" v-if="useTicketFlag">
      <div>
        <p class="form-headline">TICKET設定</p>
        <section class="form-section" v-for="(item, index) in form.tickets" :key="item.key">
          <header class="header-giftcard">
            <div class="form-group">
              <div class="form-group-item">
                <input
                  :class="{ 'form-control w-40 text-center': true, 'is-error': errors[`number${index}`] }"
                  type="tel"
                  :name="`numberTicket${index}`"
                  v-number
                  v-maxlength
                  :disabled="isClientChild"
                  maxlength="2"
                  v-model="form.tickets[index].number"
                />
              </div>
              <div class="form-group-item text-red">
                数字を変更することで順番の変更可
              </div>
            </div>
            <button v-if="!isClientChild" class="btn btn-bd-red" :disabled="!!item.id && isBtnTicketDisabled" @click="handleDeleteTicket(index)">- TICKETを削除</button>
          </header>
          <FormRow :required="true">
            <template v-slot:label>TICKET名</template>
            <template v-slot:content>
              <div class="form-content-row">
                <p v-if="item.hasHistoryTicket && item.id">{{ form.tickets[index].name }}</p>
                <input
                  v-else
                  :class="{ 'form-control': true, 'is-error': errors[`name${index}`] }"
                  :disabled="isClientChild"
                  type="text"
                  :name="`nameTicket${index}`"
                  v-trim
                  v-maxlength
                  maxlength="20"
                  v-model="form.tickets[index].name"
                />
              </div>
            </template>
          </FormRow>
          <FormRow :required="true" :isEndLabel="true">
            <template v-slot:label>TICKETデザイン</template>
            <template v-slot:endLabel>
              <button class="link" @click="showModal('giftCardModal')">デザイン名と色サンプル</button>
            </template>
            <template v-slot:content>
              <div class="form-content-row">
                <div class="form-select">
                  <select
                    :class="{ 'form-control form-select-input': true, 'is-error': errors[`typeDesign${index}`] || errors[`tickets.${index}.typeDesign`] }"
                    v-model="form.tickets[index].typeDesign"
                    :disabled="isClientChild"
                    :name="`typeDesignTicket${index}`"
                    @change="handleTypeDesignTicket($event, index)"
                  >
                    <option :value="null">選択してください</option>
                    <option v-for="month in form.tickets[index].listPattern" :key="month" :value="month">パターン{{ month }}</option>
                  </select>
                </div>
              </div>
            </template>
          </FormRow>
          <FormRow>
            <template v-slot:label>オリジナルをアップロードする</template>
            <template v-slot:content>
              <div class="form-content-row">
                <ul class="fileUpload">
                  <li class="fileUpload-item">
                    <Fileupload
                      :isBtnDeleteShown="!isClientChild"
                      :disabled="isClientChild"
                      @uploaded="uploadImage($event, index)"
                      @removed="deleteImage(index)"
                      :initialUrl="form.tickets[index].image.url"
                      :loading="form.tickets[index].isUploadLoading"
                      :isError="errors.image"
                    />
                  </li>
                  <p class="mt-auto">
                    {{ uploadImageText }}<br>
                    (Android縦の最大サイズ(xxxhdpi基準))
                  </p>
                </ul>
              </div>
            </template>
          </FormRow>
          <FormRow :required="true">
            <template v-slot:label>TICKET文字色</template>
            <template v-slot:content>
              <div class="form-content-row">
                <ul class="listGrid">
                  <li v-for="element in giftCardTypeLists" class="listGrid-item" :key="element.value" :class="element.class">
                    <label class="form-radio">
                      <input
                        :class="{ 'form-radio-input': true, 'is-error': errors[`color${index}`]  }"
                        type="radio"
                        :value="element.value"
                        :name="`colorTicket${index}`"
                        :disabled="isClientChild"
                        v-model="form.tickets[index].color"
                      />
                      <span class="form-radio-label">{{ element.label }}</span>
                    </label>
                  </li>
                </ul>
              </div>
            </template>
          </FormRow>
          <FormRow :required="true">
            <template v-slot:label>購入金額</template>
            <template v-slot:content>
              <div class="form-content-row">
                <div class="form-group">
                  <div class="form-group-item">
                    <p v-if="item.hasHistoryTicket && item.id">{{ form.tickets[index].purchaseAmount }}</p>
                    <input
                      v-else
                      :class="{ 'form-control': true, 'is-error': errors[`purchaseAmount${index}`] }"
                      type="tel"
                      :name="`purchaseAmountTicket${index}`"
                      v-number
                      v-maxlength
                      maxlength="7"
                      :disabled="isClientChild"
                      v-model="form.tickets[index].purchaseAmount"
                    />
                  </div>
                  <div class="form-group-item">
                    円
                  </div>
                </div>
              </div>
            </template>
          </FormRow>
          <FormRow :required="true">
            <template v-slot:label>TICKET内訳</template>
            <template v-slot:content>
              <div class="form-content-row">
                <div class="form-group">
                  <div class="form-group-item">
                    <p v-if="item.hasHistoryTicket && item.id">{{ form.tickets[index].amountBreakdown }}</p>
                    <input
                      v-else
                      :class="{ 'form-control': true, 'is-error': errors[`amountBreakdown${index}`] }"
                      type="tel"
                      :name="`amountBreakdownTicket${index}`"
                      v-number
                      v-maxlength
                      maxlength="7"
                      :disabled="isClientChild"
                      v-model="form.tickets[index].amountBreakdown"
                    />
                  </div>
                  <div class="form-group-item">
                    円
                  </div>
                  <div :class="{ 'form-group-item pd-0': true }">
                    ｘ
                  </div>
                  <div class="form-group-item">
                    <p v-if="item.hasHistoryTicket && item.id">{{ form.tickets[index].unitBreakdown }}</p>
                    <input
                      v-else
                      :class="{ 'form-control': true, 'is-error': errors[`unitBreakdown${index}`] }"
                      type="tel"
                      :name="`unitBreakdownTicket${index}`"
                      v-number
                      v-maxlength
                      maxlength="3"
                      :disabled="isClientChild"
                      v-model="form.tickets[index].unitBreakdown"
                    />
                  </div>
                  <div class="form-group-item">
                    枚
                  </div>
                  <div class="form-group-item pos-end text-underline text-bold pd-0">
                    {{ sumText }}{{ (form.tickets[index].amountBreakdown * form.tickets[index].unitBreakdown) | comma }}円分
                  </div>
                </div>
              </div>
            </template>
          </FormRow>
          <FormRow>
            <template v-slot:label>注釈の登録</template>
            <template v-slot:labelNote>（3,000文字以内）</template>
            <template v-slot:content>
              <div class="form-content-row">
                <textarea
                  :class="{ 'form-control form-textarea': true, 'is-error': errors[`notes${index}`] }"
                  :name="`notesTicket${index}`"
                  v-trim
                  v-maxlength
                  maxlength="3000"
                  :disabled="isClientChild"
                  v-model="form.tickets[index].notes"
                ></textarea>
              </div>
            </template>
          </FormRow>
          <FormRow>
            <template v-slot:label>TICKET発行総額（購入金額）</template>
            <template v-slot:content>
              <div class="form-content-row">
                <div class="form-group">
                  <div class="form-group-item">
                    <input
                      :class="{ 'form-control': true, 'is-error': errors[`budgetAmount${index}`] }"
                      type="tel"
                      :name="`budgetAmountTicket${index}`"
                      v-number
                      v-maxlength
                      maxlength="10"
                      :disabled="isClientChild"
                      v-model="form.tickets[index].budgetAmount"
                    />
                  </div>
                  <div class="form-group-item">
                    円
                  </div>
                </div>
              </div>
            </template>
          </FormRow>
          <FormRow>
            <template v-slot:label>TICKET所持枚数上限</template>
            <template v-slot:content>
              <div class="form-content-row">
                <p>購入(付与)して、所持することができるTICKETの枚数に上限を設ける場合に入力してください。</p>
              </div>
              <div class="form-content-row">
                <div class="form-group">
                  <div class="form-group-item">
                    <input
                      :class="{ 'form-control': true, 'is-error': errors[`limitPerPerson${index}`] }"
                      type="tel"
                      :name="`limitPerPersonTicket${index}`"
                      v-number
                      v-maxlength
                      maxlength="3"
                      :disabled="isClientChild"
                      v-model="form.tickets[index].limitPerPerson"
                    />
                  </div>
                  <div class="form-group-item">
                    枚
                  </div>
                </div>
              </div>
            </template>
          </FormRow>
          <FormRow>
            <template v-slot:label>同一顧客の購入口数上限</template>
            <template v-slot:content>
              <div class="form-content-row">
                <p>子イベントにて顧客自身が購入（永久的に）できる口数の上限を設ける場合に入力してください。</p>
              </div>
              <div class="form-content-row">
                <div class="form-group">
                  <div class="form-group-item">
                    <input
                      :class="{ 'form-control': true, 'is-error': errors[`limitPerCharge${index}`] }"
                      type="tel"
                      :name="`limitPerChargeTicket${index}`"
                      v-number
                      v-maxlength
                      maxlength="2"
                      :disabled="isClientChild"
                      v-model="form.tickets[index].limitPerCharge"
                    />
                  </div>
                  <div class="form-group-item">
                    口
                  </div>
                </div>
              </div>
            </template>
          </FormRow>
          <FormRow>
            <template v-slot:label>TICKETを公開</template>
            <template v-slot:toggle>
              <label class="form-toggle">
                <input
                  class="form-toggle-input"
                  type="checkbox"
                  :name="`validFlagTicket${index}`"
                  :disabled="(!!item.id && isBtnTicketDisabled) || isClientChild"
                  :checked="form.tickets[index].validFlag"
                  @change="handleValidFlag($event, index)"
                />
                <span class="form-toggle-set">
                  <span class="form-toggle-bg"></span>
                  <span class="form-toggle-circle"></span>
                </span>
              </label>
            </template>
          </FormRow>
        </section>
        <div class="form-content-row" v-if="isBtnTicketShown">
          <div class="form-group">
            <div class="form-group-item">
              <button class="btn btn-bd-main" @click="addTicket">＋TICKETを追加</button>
            </div>
            <div class="form-group-item text-red">
              TICKETは最大<span class="text-bold text-underline">20</span>個まで作成可能
            </div>
          </div>
        </div>
      </div>
    </transition>
    <ModalInformation
      :handleCloseModal="closeModal"
      :isInforModalShown="modal"
    />
    <Modal v-if="bankListModal">
      <template v-slot:headline>利用可能な金融機関</template>
      <template v-slot:body>
        <FormRow>
          <template v-slot:label>
            <span class="form-label-note">金融機関名・金融機関コードを入力</span>
          </template>
          <template v-slot:content>
            <div class="form-content-row mt-3">
              <div class="form-icon">
                <input
                  class="form-control"
                  type="text"
                  name="name"
                  placeholder="金融機関名・金融機関コードを入力して検索"
                  v-trim
                  v-model="searchForm.name"
                  @keyup="searchBankName"
                />
              </div>
            </div>
          </template>
        </FormRow>
        <section class="section">
          <div class="row">
            <div class="col">
              <Panel type="board" :padding="false" :hasFooterBorder="true">
                <template v-slot:body>
                  <div class="scrollX">
                    <Table
                      ref="table"
                      :hasTableBorder="true"
                      :data-table="dataTable"
                      :labels="labels"
                    ></Table>
                  </div>
                </template>
                <template v-slot:footer>
                  <PaginationLog
                    ref="pagination"
                    :searchConditions="searchConditions"
                    :listLength="bankCount"
                    :subdomain="subdomain"
                    :modulePath="modulePath"
                    :limit="10"
                    :noGetPaginationList="false"
                  ></PaginationLog>
                </template>
              </Panel>
            </div>
          </div>
        </section>
        </template>
        <template v-slot:footer>
          <ul class="listGrid justify-content-end">
            <li class="listGrid-item">
              <button class="btn btn-lg btn-white" type="button" @click="closeModal('bankListModal')">キャンセル</button>
            </li>
          </ul>
        </template>
    </Modal>
    <Modal v-if="giftCardModal">
      <template v-slot:body>
        <p class="gift-card-logo"><img class="img-fluid" src="@/assets/img/common/giftCard.png" alt="giftCard" /></p>
      </template>
      <template v-slot:footer>
        <ul class="listGrid justify-content-end">
          <li class="listGrid-item">
            <button class="btn btn-lg btn-white" type="button" @click="closeModal('giftCardModal')">キャンセル</button>
          </li>
        </ul>
      </template>
    </Modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { required, minLength, maxLength, between, minValue, email } from 'vuelidate/lib/validators';
//mixins
import modal from '@/mixins/plugin/modal';
import search from '@/mixins/plugin/search';
import error from '@/mixins/plugin/error';
import event from '@/mixins/module/event';
//components
import FormRow from '@/components/FormRow.vue';
import Modal from '@/components/Modal.vue';
import SearchSelect from '@/components/SearchSelect.vue';
import SearchSelectDisabled from '@/components/SearchSelectDisabled.vue';
import DateRange from '@/components/DateRange.vue';
import ModalInformation from '@/components/ModalInformation.vue';
import Fileupload from '@/components/Fileupload.vue';
import Table from '@/components/Table.vue';
import PaginationLog from '@/components/PaginationLog.vue';
//helpers
import { formatArray, convertNumberToArray, generateRandomId, formatStartWithSeconds } from '@/helpers/formatData';
//constants
import { EventConstants } from '@/constants/event';
import { StatusConstants } from '@/constants/status';
//helpers
import {
  isDuplicateField,
  isNumberLowercaseDash,
  notConsecutiveDashes,
  lessValue,
  isUrl,
  isExpireDaysWithMinValue,
  isDateWithMinMaxValue,
  isMultipleNumber,
  isLimitWithValidValue,
  isLimitAndUnitWithValidValue,
  hasValueOrNewUpload,
  isUnique,
  isDateWithSameAsValue,
} from '@/helpers/validators';

export default {
  mixins: [error, event, modal, search],
  components: {
    FormRow,
    SearchSelect,
    DateRange,
    ModalInformation,
    Modal,
    Fileupload,
    PaginationLog,
    Table,
    SearchSelectDisabled,
  },
  props: {
    isEdit: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      form: {},
      // hasGroup: false,
      payConbini: false,
      giftCardModal: false,
      prefixs: ['registerDisp13', 'usablePeriod', 'chargeablePeriod', 'paymentablePeriod' , 'convenienceStore', 'maruPay', 'creditcard', 'shopForm'],
      expirationMonthCount: EventConstants.EXPIRATION_MONTH_COUNT,
      bankListModal: false,
      modulePath: 'event/getBankList',
      searchConditions: {},
      searchFields: ['name'],
      typingInterval: null,
      storeModule: 'event',
      giftCardTypeLists: Object.values(StatusConstants.giftCardType),
      uploadImageText: '※推奨サイズ　横1,280pxｘ縦652px',
      sumText: '計　',
      convertNumberToArray,
      giftCardData: {
        key: null,
        id: null,
        number: 0,
        typeDesign: null,
        color: null,
        purchaseAmount: null,
        amountBreakdown: null,
        unitBreakdown: null,
        notes: null,
        budgetAmount: null,
        limitPerPerson: null,
        limitPerCharge: null,
        validFlag: true,
        imageId: null,
        image: {},
        isUploadLoading: false,
        listPattern: [],
      },
      imageUrl: null,
      useChargeSmsTypes: Object.values(EventConstants.useChargeSmsType),
      selectedIds: new Set(),
    }
  },
  computed: {
    ...mapGetters({
      newEvent: 'event/newEvent',
      childEventDetail: 'event/childEventDetail',
      parentEventDetail: 'event/parentEventDetail',
      chargeChoices: 'common/chargeAmountChoices',
      convenienceStores: 'common/convenienceStores',
      childClients: 'client/clientList',
      isClientChild: 'auth/isClientChild',
      eventType: 'event/eventType',
      useTicketFlag: 'event/useTicketFlag',
      identityVerification: 'event/identityVerification',
      useSms: 'event/useSms',
      bankList: 'event/bankList',
      bankCount: 'event/bankCount',
      isUseCpmParentEvent: 'event/isUseCpmParentEvent',
    }),
    subdomain() {
      return this.$route.params.subdomain;
    },
    directory() {
      return this.$route.params?.directory;
    },
    creditCards() {
      return Object.values(EventConstants.creditCards);
    },
    value3ds() {
      return Object.values(EventConstants.value3ds);
    },
    verificationTypes() {
      let data = JSON.parse(JSON.stringify(EventConstants.verificationType));
      if (!this.identityVerification) delete data.identity;
      if (!this.useSms) delete data.sms;
      if (!this.identityVerification || !this.useSms) delete data.both;
      return Object.values(data);
    },
    giftType() {
      return EventConstants.GIFT_EVENT_TYPE;
    },
    chargeType() {
      return EventConstants.CHARGE_EVENT_TYPE;
    },
    paymentType() {
      return EventConstants.PAYMENT_EVENT_TYPE;
    },
    chargeGiftCardType() {
      return EventConstants.CHARGE_GIFT_CARD_EVENT_TYPE;
    },
    paymentGiftCardType() {
      return EventConstants.PAYMENT_GIFT_CARD_EVENT_TYPE;
    },
    isChargeOrPaymentGiftCardType() {
      return [this.chargeGiftCardType, this.paymentGiftCardType].includes(this.eventType);
    },
    isEditChargeGiftCard() {
      return this.isEdit && (this.eventType === this.chargeGiftCardType);
    },
    isPaymentType() {
      return this.eventType === this.paymentType;
    },
    isChargeType() {
      return this.form.type === this.chargeType;
    },
    isEditWithPaymentType() {
      return this.isEdit && this.isPaymentType && this.$permission.isGmo();
    },
    isEditWithoutPaymentType() {
      return this.isEdit && (!this.isPaymentType || (this.isPaymentType && !this.$permission.isGmo())) && !this.useTicketFlag;
    },
    isEditTransferPaymentToCharge() {
      return this.isEdit && this.eventType === this.paymentType && this.form.type === this.chargeType;
    },
    selectedCharges() {
      const list = this.chargeChoices.filter(item => {
        return this.form.chargeChoices && this.form.chargeChoices.find(id => String(item.id) === id)
      });
      return formatArray(list, 'name');
    },
    selectedClients() {
      return formatArray(this.form.clients, 'name');
    },
    shopFormTypeList() {
      return Object.values(EventConstants.SHOP_FORM_TYPE);
    },
    parentClients() {
      return this.parentEventDetail?.clients || [];
    },
    checkedGroups() {
      return this.form.groups?.length;
    },
    isBtnTicketShown() {
      return (this.form.tickets?.length < EventConstants.GIFT_CARD_MAX_LENGTH) && !this.isClientChild;
    },
    isBtnTicketDisabled() {
      return this.isEdit && ![EventConstants.status.unregistered.value, EventConstants.status.preparingPublic.value, EventConstants.status.paused.value].includes(this.childEventDetail?.status?.id);
    },
    typeDesignArray() {
      return (this.form?.tickets || []).map(item => item.typeDesign);
    },
    initialSearchConditions() {
      return {
        directory: this.isEdit ? this.directory : '',
      }
    },
    dataTable() {
      return (this.bankList || []).map(item => {
        return {
          ...item
        };
      });
    },
    labels() {
      return {
        id: '金融機関コード',
        name: '金融機関名'
      }
    },
    isPaymentTypeAndNotUseTicketFlag() {
      return this.form.type !== this.paymentType && !this.useTicketFlag;
    },
    isChargeTypeOrUseTicketFlag() {
      return this.isChargeType || (this.useTicketFlag && this.notPaymentType);
    },
    isViewAllHistory() {
      return (this.isChargeType || this.form?.type === this.paymentType) && this.form.otherClients?.length && !this.useTicketFlag && !this.isClientChild;
    },
    notPaymentType() {
      return ![this.paymentType, this.paymentGiftCardType].includes(this.form.type);
    },
    bankError() {
      return this.errors.chargeType?.maruPay?.use || this.errors['chargeType.maruPay.use'];
    },
    storeError() {
      return this.errors.chargeType?.store?.use || this.errors['chargeType.store.use'];
    },
    newParentClients() {
      return this.parentClients.map(item => ({
        ...item,
        isNewItem: !this.selectedIds.has(item.id),
      }));
    }
  },
  validations() {
    let form = {
      name: { required, maxLength: maxLength(20) },
      description: { maxLength: maxLength(2000) },
      type: { selectRequired: required },
      chargeChoices: { optionMaxLength: maxLength(3) },
      clients: { selectRequired: required },
      directory: { required, isNumberLowercaseDash, notConsecutiveDashes , minLength: minLength(3), maxLength: maxLength(32) },
      informationUrl: { isUrl },
      shopForm: {
        type: { selectRequired: required },
        email: { required, email, maxLength: maxLength(50) },
      },
      usablePeriod: {},
      chargeablePeriod: {},
      paymentablePeriod: {},
      choiceChargeType: {},
      chargeType: {
        creditcard: {},
        convenienceStore: {},
        payeasy: {},
        maruPay: {},
        store: {},
      },
      registerDisp1: {},
      registerDisp11: {},
      registerDisp12: {},
      registerDisp13: {},
      daysAllowCancelCharge: {},
      settingVerify: {},
      useChargeSms: {},
      tickets: {},
      chargeExpirationMonths: {},
      sendMailAfterDaysCharge: {},
      rate: {},
      groups: {},
      budget: { between: between(1, 20000000000) },
      minimumAmount: { between: between(100, 100000), lessBudgetValue: lessValue(this.form.budget, this.setBudgetError) },
      minimumUnit: { between: between(1, 100000), lessBudgetValue: lessValue(this.form.budget, this.setBudgetError) },
      chargeLimit: { between: between(1, 1000000) },
      maximumAmount: { between: between(1, 1000000), lessBudgetValue: lessValue(this.form.budget, this.setBudgetError) },
    };
    if (this.isPaymentTypeAndNotUseTicketFlag) {
      if (this.form.groups) {
        form.groups = {
          $each: {
            name: { required, maxLength: maxLength(20) },
            rate: { required, between: between('1.000', '100.000') },
          },
          duplicateGroupName: (val) => {
            const result = isDuplicateField(val, 'name');
            if (!result) {
              this.form.groups.forEach((_, index) => this.errors[`name${index}`] = true)
            }
            return result
          },
        }
      }
      form.rate = { required, between: between('1.000', '1000.000') }
    }
    if (this.form.usablePeriod?.start || this.form.usablePeriod?.end) {
      form.usablePeriod = {
        start: { selectRequired: required },
        end: { selectRequired: required }
      }
    }
    if (this.form.chargeablePeriod?.start || this.form.chargeablePeriod?.end) {
      form.chargeablePeriod = {
        start: { selectRequired: required },
        end: { selectRequired: required }
      }
    }
    if (this.form.paymentablePeriod?.start || this.form.paymentablePeriod?.end) {
      form.paymentablePeriod = {
        start: { selectRequired: required },
        end: { selectRequired: required }
      }
    }
    if (this.form.chargeType.convenienceStore?.use) {
      form.registerDisp1 = { required, maxLength: maxLength(16) };
      form.registerDisp11 = { required, maxLength: maxLength(21) };
      form.registerDisp12 = { required, maxLength: maxLength(12) };
      form.registerDisp13 = {
        start: { required },
        end: {
          required,
          isDuplicateHourMinute: isDateWithSameAsValue(this.form.registerDisp13?.start, this.form.registerDisp13?.end)
        }
      }
    }
    if (this.isEdit && this.childEventDetail?.budget) {
      form.budget = { between: between(1, 20000000000), updatedMinValue: minValue(this.childEventDetail.budget) }
    }
    if (this.isEdit && this.$permission.isOffice() && this.isEventUnregistered) {
      form.description = { required, maxLength: maxLength(1000) };
      if (this.isPaymentTypeAndNotUseTicketFlag) {
        form.minimumAmount = { ...form.minimumAmount, required };
        form.choiceChargeType = { selectRequired: () => {
          const choice = Object.keys(this.form.chargeType).find(key => this.form.chargeType[key].use)
          return choice ? true : false;
        }}
      }
    }
    if (this.isEdit && !this.isEventUnregistered) {
      form = {
        ...form,
        statusEventRequired: (val) => {
          let result = true;
          if (!val.description) {
            this.errors.description = true;
            result = false;
          }
          if (this.isPaymentTypeAndNotUseTicketFlag && !val.chargeType.convenienceStore.use && !val.chargeType.creditcard.use && !val.chargeType.payeasy.use && !val.chargeType.maruPay.use && !val.chargeType.store.use) {
            this.errors.choiceChargeType = true;
            result = false;
          }
          if (this.isPaymentTypeAndNotUseTicketFlag && !val.minimumAmount) {
            this.errors.minimumAmount = true;
            result = false;
          }
          if (!result) {
            this.$message.statusEventRequired();
          }
          return result;
        }
      }
    }
    if (this.form.chargeType.convenienceStore?.use && this.isPaymentTypeAndNotUseTicketFlag) {
      form.chargeType.convenienceStore.stores = { selectRequired: required };
    }
    if (this.form.chargeType.maruPay?.use && this.isPaymentTypeAndNotUseTicketFlag) {
      form.chargeType.maruPay.bankCodes = { maxLength: maxLength(49) };
    }
    if (this.form.chargeType.creditcard?.use && this.isPaymentTypeAndNotUseTicketFlag) {
      form.chargeType.creditcard.cards = { selectRequired: required };
    }
    if (this.form?.chargeExpirationMonths && this.form?.sendMailAfterDaysCharge) {
      form.sendMailAfterDaysCharge = { dayMaxLength: maxLength(10), validMailAfterDaysCharge: isExpireDaysWithMinValue(2) };
    }
    if ((this.form.type === this.chargeType || this.form.type === this.chargeGiftCardType) && this.form?.daysAllowCancelCharge) {
      form.daysAllowCancelCharge = { limitDateWithMinMaxValue: isDateWithMinMaxValue(0, 180) }
    }
    if (this.imageUrl || !this.imageUrl) {
      if (this.form.tickets) {
      form.tickets = {
        $each: {
          number: {
            required,
            duplicateCardNumber: isUnique(this.form.tickets, 'number'),
            limitWithMinMaxValue: isDateWithMinMaxValue(1, 20),
          },
          name: {
            required,
            duplicateCardName: isUnique(this.form.tickets, 'name'),
            maxLength: maxLength(20)
          },
          typeDesign: {
            selectRequired: (val, item) => hasValueOrNewUpload(val, item?.image?.url),
          },
          color: { selectRequired: required },
          purchaseAmount: { required, between: between(1, 9999999) },
          amountBreakdown: { required, between: between(1, 9999999) },
          unitBreakdown: { required, between: between(1, 999) },
          notes: { maxLength: maxLength(3001) },
          budgetAmount: {
            between: between(1, 9999999999),
            isMultipleNumber: (val, item) => isMultipleNumber(val, item.purchaseAmount)
          },
          limitPerPerson: {
            between: between(1, 999),
            isLimitWithValidValue: isLimitAndUnitWithValidValue,
          },
          limitPerCharge: {
            between: between(1, 99),
            isLimitWithValidValue: (val, item) => isLimitWithValidValue(val, item.budgetAmount, item.purchaseAmount),
            isLimitWithValidBudget: (val, item) => isLimitWithValidValue(val, item.limitPerPerson, item.unitBreakdown),
          },
        },
      };
    }
    }
    return { form };
  },
  methods: {
    initForm() {
      this.form = {
        name: '',
        description: '',
        type: '',
        clients: [],
        otherClients: [],
        directory: '',
        informationUrl: '',
        shopForm: {
          type: '',
          email: '',
        },
        chargeChoices: [],
        usablePeriod: {},
        chargeablePeriod: {},
        paymentablePeriod: {},
        chargeType: {
          creditcard: {
            use: false,
            cards: [],
            '3ds': 0,
          },
          convenienceStore: {
            use: false,
            stores: [],
          },
          payeasy: {
            use: false
          },
          maruPay: {
            use: false,
            bankCodes: '',
          },
          store: {
            use: false,
          }
        },
        groups: null,
        privacyPolicyUrl: '',
        multiPaymentKey: {},
        cardImageTemplateId: '',
        cardImageUpload: {},
        logo: {},
        headerLogo: {},
        paymentCycles: [],
        showCustomerFlag: false,
        addCategories: [],
        categories: [],
        deleteCategories: [],
        chargeExpirationMonths: '',
        sendMailAfterDaysCharge: null,
        daysAllowCancelCharge: 180,
        settingVerify: 0,
        useChargeSms: 0,
        tickets: [],
        viewAllhistory: true,
        useContactFrontFlag: true,
        registerDisp1: null,
        registerDisp11: null,
        registerDisp12: null,
        registerDisp13: {},
      }
    },
    handleChargeChoice(e) {
      const value = e.target.value;
      const index = this.form.chargeChoices.indexOf(value);
      if (index !== -1) {
        this.form.chargeChoices.splice(index, 1);
      } else {
        this.form.chargeChoices.push(value)
      }
    },
    handleUseGroupFlag(e) {
      if (this.isEditWithoutPaymentType) {
        this.$message.showError('notEditUseGroupFlag');
        e.target.checked = !e.target.checked;
      } else if (e.target.checked) {
        this.form.groups = [
          { name: '', rate: '' },
          { name: '', rate: '' },
        ];
      } else {
        this.form.groups = null;
      }
    },
    handleClients(value) {
      this.form.clients = value;
    },
    handleOtherClients(value) {
      this.form.otherClients = value;
    },
    checkChargeChoices(id) {
      if (this.form.chargeChoices) {
        return this.form.chargeChoices.find(item => item === id);
      }
      return false;
    },
    onChangeDateRange(field, type, val) {
      this.form[field] = {
        ...this.form[field],
        [type]: val,
      }
    },
    convertConvenienceStore() {
      const storeArray = [ ...this.form.chargeType.convenienceStore?.stores ];
      const codeList = this.convenienceStores.map(item => item.code);
      storeArray.sort((a, b) => codeList.indexOf(a) - codeList.indexOf(b));
      return storeArray;
    },
    setBudgetError() {
      this.errors.budget = true;
    },
    formatData() {
      const bankCodes = this.form.chargeType?.maruPay?.bankCodes;
      this.form = {
        ...this.form,
        type: this.form.type,
        rate: this.form.rate,
        ...(this.form.groups?.length && {
          groups: this.form.groups.map(e => ({ ...e, rate: e?.rate }))
        }),
        paymentablePeriod: formatStartWithSeconds(this.form.paymentablePeriod),
        chargeablePeriod: formatStartWithSeconds(this.form.chargeablePeriod),
        chargeType: {
          ...this.form.chargeType,
          maruPay: {
            ...this.form.chargeType?.maruPay,
            bankCodes: (typeof bankCodes !== 'string') ? bankCodes?.join(',') : bankCodes
          }
        },
        registerDisp13From: this.form.chargeType.convenienceStore.use ? this.form.registerDisp13?.start : null,
        registerDisp13To: this.form.chargeType.convenienceStore.use ? this.form.registerDisp13?.end : null,
      }
      return;
    },
    validate() {
      this.form.cardImage = this.convertCardImage(this.form);
      if (this.form.chargeType.convenienceStore) {
        this.form.chargeType.convenienceStore.stores = this.convertConvenienceStore();
      }
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.getErrors(this.$v.form, 'event');
        return false
      } else {
        this.formatData();
        if (this.isEdit) {
          this.$store.commit("event/SET_CHILD_EVENT_DETAIL", {
            ...this.form,
            ...(this.isEditWithPaymentType && {
              hasGroup: this.form.groups?.length,
            })
          });
        } else {
          this.$store.commit('event/SET_NEW_CHILD_EVENT', {
            ...this.form,
            hasGroup: this.form.groups
          })
        }
        return true
      }
    },
    async getDetail() {
      if (this.isEdit && this.childEventDetail.directory !== this.$route.params.directory) {
        await this.$store.dispatch('event/getChildEventDetail');
      }
    },
    async getParentEventInfo() {
      await this.$store.dispatch('event/getParentEventDetail', this.$route.params.subdomain);
    },
    resetDayCharge() {
      this.form.chargeExpirationMonths = '';
      this.form.sendMailAfterDaysCharge = null;
      this.errors.sendMailAfterDaysCharge = false;
    },
    addTicket() {
      const newGiftCardData = {
        ...this.giftCardData,
        key: generateRandomId(),
        image: { url: '', id: null },
        imageId: null,
        number: this.form.tickets.length + 1,
        listPattern: convertNumberToArray(20).filter(e => !this.typeDesignArray.includes(e)),
      };
      this.form.tickets.push(newGiftCardData);
    },
    handleDeleteTicket(index) {
      if (this.form.tickets[index].isUsed) {
        this.$message.showError('notDeleteTicketCardIfUsed');
      } else if (!!this.form.tickets[index]?.id && this.isBtnTicketDisabled) {
        this.$message.showError('notDeleteTicketCardIfNotStatus');
      } else {
        this.$store.dispatch('common/setErrorFields', { ['number' + index]: false} )
        this.form.tickets.splice(index, 1);
        this.form.tickets = this.form.tickets.map(elm => {
          const newListPattern = elm.typeDesign ? elm.listPattern : convertNumberToArray(20).filter(e => !this.typeDesignArray.includes(e));
          return { ...elm, listPattern: newListPattern };
        });
      }
    },
    async uploadImage(url, index) {
      this.form.tickets[index].isUploadLoading = true;
      this.form.tickets[index].imageId = await this.$store.dispatch('common/uploadImage', {
        image: url,
      });
      this.form.tickets[index].image.url = url;
      this.imageUrl = url;
      this.form.tickets[index].isUploadLoading = false;
    },
    deleteImage(index) {
      this.form.tickets[index].imageId = null;
      this.form.tickets[index].image = {};
      this.imageUrl = null;
    },
    handleValidFlag(e, index) {
      if (!!this.form.tickets[index]?.id && this.isBtnTicketDisabled) {
        this.$message.showError('notEditBtnValidFlag');
        e.target.checked = !e.target.checked;
      } else if (e.target.checked) {
        this.$message.reset();
        this.form.tickets[index].validFlag = 1;
      } else {
        this.$message.reset();
        this.form.tickets[index].validFlag = 0;
      }
    },
    convertTicketImages() {
      this.form.tickets = (this.childEventDetail?.tickets || []).map(e => ({ ...e, image: e?.image || { url: '', id: null }, isUploadLoading: false }));
    },
    handleTypeDesignTicket(event, index) {
      this.form.tickets = this.form.tickets.map((elm, itemIndex) => {
        const newListPattern = (itemIndex === index) ? elm.listPattern : convertNumberToArray(20).filter(e => e === elm.typeDesign || !this.typeDesignArray.includes(e));
        return { ...elm, listPattern: newListPattern };
      });
    },
    searchBankName(event) {
      const text = event.target.value;
      clearTimeout(this.typingInterval);
      this.typingInterval = setTimeout(() => {
        if (text?.trim()) {
          this.searchForm.name = text;
          this.search();
        } else {
          this.searchForm.name = '';
          this.search();
        }
      }, 300);
    },
    resetList() {
      this.typingInterval = null;
      this.searchForm.name = '';
      this.searchConditions = { directory: this.directory };
      this.$store.commit('event/SET_BANK_LIST', []);
      this.$store.commit('event/SET_COUNT_BANK', 0);
    },
    async handleShowBankModal() {
      const res = await this.$store.dispatch('event/getBankList', {
        ...this.initialSearchConditions,
        name: '',
        offset: 1,
        limit: 10,
      });
      if (res?.data) {
        this.showModal('bankListModal');
      }
    }
  },
  watch: {
    'form.chargeType.convenienceStore.use'(val) {
      if (!val && this.isPaymentTypeAndNotUseTicketFlag) {
        this.form.chargeType.convenienceStore.stores = [];
        this.form.registerDisp1 = null;
        this.form.registerDisp11 = null;
        this.form.registerDisp12 = null;
        this.form.registerDisp13 = {};
      }
    },
    'form.chargeType.creditcard.use'(val) {
      if (!val && this.isPaymentTypeAndNotUseTicketFlag) {
        this.form.chargeType.creditcard.cards = [];
        this.form.useChargeSms = 0;
        this.form.settingVerify = 0;
      }
    },
    'form.chargeType.maruPay.use'(val) {
      if (!val && !this.notPaymentType) {
        this.form.chargeType.maruPay.bankCodes = '';
      }
    },
    'form.type'(val) {
      this.$store.commit("event/SET_CHILD_EVENT_TYPE", val);
      if (val && (val === this.paymentType || val === this.paymentGiftCardType)) {
        this.form.groups = null;
        this.form.hasGroup = false;
        this.resetDayCharge();
        this.form.daysAllowCancelCharge = null;
        this.errors.daysAllowCancelCharge = false;
      }
    },
    'form.clients'(val) {
      const parentIds = (val.length ? val : this.parentClients).map(item => item.id);
      if (parentIds.length) {
        this.$store.dispatch('client/getClientList', {
          type: 'children',
          limit: 9999,
          parentId: (val.length ? val : this.parentClients).map(item => item.id)
        })
      }
    },
    childClients() {
      const selectedItems = [...this.form.otherClients]
      this.form.otherClients = selectedItems.filter(selectedItem => {
        return this.childClients.some(item => item.id === selectedItem.id);
      });
    },
    'form.chargeExpirationMonths'(val) {
      if (!val) {
        this.resetDayCharge();
      }
    },
    bankListModal(val) {
      if (val) {
        this.resetList();
      }
    }
  },
  created() {
    const loading = this.$loading.show();
    this.initForm();
    Promise.all([
      this.$store.dispatch('event/getParentEventDetail', this.subdomain),
      this.$store.dispatch('common/getChargeAmountChoices'),
      this.$store.dispatch('common/getConvenienceStores'),
      this.getParentEventInfo(),
      this.getDetail(),
    ]).then(() => {
      if (this.isEdit) {
        this.form = { ...this.form, ...this.childEventDetail }
        this.form.paymentCycles = this.form.paymentCycles?.map((item) => item.id || item) || [];
        if (this.form.chargeType?.maruPay?.use) {
          this.form.chargeType.maruPay.bankCodes = this.childEventDetail.chargeType?.maruPay?.bankCodes?.join(",") || '';
        }
        if (this.form.chargeType.convenienceStore?.use) {
          this.form.registerDisp13 = {
            start: this.childEventDetail?.registerDisp13From || null,
            end: this.childEventDetail?.registerDisp13To || null,
          }
        }
        this.convertTicketImages();
      } else {
        this.form = { ...this.form, ...this.newEvent }
      }
      this.initialErrors();
      this.selectedIds = new Set((this.form.clients || []).map(item => item.id));
    }).finally(() => {
      this.$loading.clear(loading);
    })
  },
}
</script>

<style scoped>
.header-giftcard {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}

.w-40 {
  width: 40px !important;
}

.btn:disabled {
  border: none;
}

.gift-card-logo {
  width: 375px;
  margin: 0 auto;
}

.value3ds-item {
  padding: 8px 16px;
}
</style>
